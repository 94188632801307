/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-row {
  display: flex;
  flex-flow: row wrap;
}

.ant-row::before,
.ant-row::after {
  display: flex;
}

.ant-row-no-wrap {
  flex-wrap: nowrap;
}

.ant-row-start {
  justify-content: flex-start;
}

.ant-row-center {
  justify-content: center;
}

.ant-row-end {
  justify-content: flex-end;
}

.ant-row-space-between {
  justify-content: space-between;
}

.ant-row-space-around {
  justify-content: space-around;
}

.ant-row-top {
  align-items: flex-start;
}

.ant-row-middle {
  align-items: center;
}

.ant-row-bottom {
  align-items: flex-end;
}

.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}

.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.ant-col-push-24 {
  left: 100%;
}

.ant-col-pull-24 {
  right: 100%;
}

.ant-col-offset-24 {
  margin-left: 100%;
}

.ant-col-order-24 {
  order: 24;
}

.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}

.ant-col-push-23 {
  left: 95.83333333%;
}

.ant-col-pull-23 {
  right: 95.83333333%;
}

.ant-col-offset-23 {
  margin-left: 95.83333333%;
}

.ant-col-order-23 {
  order: 23;
}

.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.ant-col-push-22 {
  left: 91.66666667%;
}

.ant-col-pull-22 {
  right: 91.66666667%;
}

.ant-col-offset-22 {
  margin-left: 91.66666667%;
}

.ant-col-order-22 {
  order: 22;
}

.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.ant-col-push-21 {
  left: 87.5%;
}

.ant-col-pull-21 {
  right: 87.5%;
}

.ant-col-offset-21 {
  margin-left: 87.5%;
}

.ant-col-order-21 {
  order: 21;
}

.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.ant-col-push-20 {
  left: 83.33333333%;
}

.ant-col-pull-20 {
  right: 83.33333333%;
}

.ant-col-offset-20 {
  margin-left: 83.33333333%;
}

.ant-col-order-20 {
  order: 20;
}

.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}

.ant-col-push-19 {
  left: 79.16666667%;
}

.ant-col-pull-19 {
  right: 79.16666667%;
}

.ant-col-offset-19 {
  margin-left: 79.16666667%;
}

.ant-col-order-19 {
  order: 19;
}

.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}

.ant-col-push-18 {
  left: 75%;
}

.ant-col-pull-18 {
  right: 75%;
}

.ant-col-offset-18 {
  margin-left: 75%;
}

.ant-col-order-18 {
  order: 18;
}

.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}

.ant-col-push-17 {
  left: 70.83333333%;
}

.ant-col-pull-17 {
  right: 70.83333333%;
}

.ant-col-offset-17 {
  margin-left: 70.83333333%;
}

.ant-col-order-17 {
  order: 17;
}

.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.ant-col-push-16 {
  left: 66.66666667%;
}

.ant-col-pull-16 {
  right: 66.66666667%;
}

.ant-col-offset-16 {
  margin-left: 66.66666667%;
}

.ant-col-order-16 {
  order: 16;
}

.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.ant-col-push-15 {
  left: 62.5%;
}

.ant-col-pull-15 {
  right: 62.5%;
}

.ant-col-offset-15 {
  margin-left: 62.5%;
}

.ant-col-order-15 {
  order: 15;
}

.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.ant-col-push-14 {
  left: 58.33333333%;
}

.ant-col-pull-14 {
  right: 58.33333333%;
}

.ant-col-offset-14 {
  margin-left: 58.33333333%;
}

.ant-col-order-14 {
  order: 14;
}

.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}

.ant-col-push-13 {
  left: 54.16666667%;
}

.ant-col-pull-13 {
  right: 54.16666667%;
}

.ant-col-offset-13 {
  margin-left: 54.16666667%;
}

.ant-col-order-13 {
  order: 13;
}

.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}

.ant-col-push-12 {
  left: 50%;
}

.ant-col-pull-12 {
  right: 50%;
}

.ant-col-offset-12 {
  margin-left: 50%;
}

.ant-col-order-12 {
  order: 12;
}

.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}

.ant-col-push-11 {
  left: 45.83333333%;
}

.ant-col-pull-11 {
  right: 45.83333333%;
}

.ant-col-offset-11 {
  margin-left: 45.83333333%;
}

.ant-col-order-11 {
  order: 11;
}

.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.ant-col-push-10 {
  left: 41.66666667%;
}

.ant-col-pull-10 {
  right: 41.66666667%;
}

.ant-col-offset-10 {
  margin-left: 41.66666667%;
}

.ant-col-order-10 {
  order: 10;
}

.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.ant-col-push-9 {
  left: 37.5%;
}

.ant-col-pull-9 {
  right: 37.5%;
}

.ant-col-offset-9 {
  margin-left: 37.5%;
}

.ant-col-order-9 {
  order: 9;
}

.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.ant-col-push-8 {
  left: 33.33333333%;
}

.ant-col-pull-8 {
  right: 33.33333333%;
}

.ant-col-offset-8 {
  margin-left: 33.33333333%;
}

.ant-col-order-8 {
  order: 8;
}

.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}

.ant-col-push-7 {
  left: 29.16666667%;
}

.ant-col-pull-7 {
  right: 29.16666667%;
}

.ant-col-offset-7 {
  margin-left: 29.16666667%;
}

.ant-col-order-7 {
  order: 7;
}

.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}

.ant-col-push-6 {
  left: 25%;
}

.ant-col-pull-6 {
  right: 25%;
}

.ant-col-offset-6 {
  margin-left: 25%;
}

.ant-col-order-6 {
  order: 6;
}

.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}

.ant-col-push-5 {
  left: 20.83333333%;
}

.ant-col-pull-5 {
  right: 20.83333333%;
}

.ant-col-offset-5 {
  margin-left: 20.83333333%;
}

.ant-col-order-5 {
  order: 5;
}

.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.ant-col-push-4 {
  left: 16.66666667%;
}

.ant-col-pull-4 {
  right: 16.66666667%;
}

.ant-col-offset-4 {
  margin-left: 16.66666667%;
}

.ant-col-order-4 {
  order: 4;
}

.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.ant-col-push-3 {
  left: 12.5%;
}

.ant-col-pull-3 {
  right: 12.5%;
}

.ant-col-offset-3 {
  margin-left: 12.5%;
}

.ant-col-order-3 {
  order: 3;
}

.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.ant-col-push-2 {
  left: 8.33333333%;
}

.ant-col-pull-2 {
  right: 8.33333333%;
}

.ant-col-offset-2 {
  margin-left: 8.33333333%;
}

.ant-col-order-2 {
  order: 2;
}

.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.ant-col-push-1 {
  left: 4.16666667%;
}

.ant-col-pull-1 {
  right: 4.16666667%;
}

.ant-col-offset-1 {
  margin-left: 4.16666667%;
}

.ant-col-order-1 {
  order: 1;
}

.ant-col-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-offset-0 {
  margin-left: 0;
}

.ant-col-order-0 {
  order: 0;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}

.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}

.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}

.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}

.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}

.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}

.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}

.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}

.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}

.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}

.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}

.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}

.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}

.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}

.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}

.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}

.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}

.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}

.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}

.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}

.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}

.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}

.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}

.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}

.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}

.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}

.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}

.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}

.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}

.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}

.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}

.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}

.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}

.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}

.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}

.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}

.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}

.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}

.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}

.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}

.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}

.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}

.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}

.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}

.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}

.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}

.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}

.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}

.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}

.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}

.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}

.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}

.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}

.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}

.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}

.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}

.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}

.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}

.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}

.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}

.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}

.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}

.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}

.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}

.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}

.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}

.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}

.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}

.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}

.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}

.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}

.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}

.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.ant-col-xs-push-24 {
  left: 100%;
}

.ant-col-xs-pull-24 {
  right: 100%;
}

.ant-col-xs-offset-24 {
  margin-left: 100%;
}

.ant-col-xs-order-24 {
  order: 24;
}

.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}

.ant-col-xs-push-23 {
  left: 95.83333333%;
}

.ant-col-xs-pull-23 {
  right: 95.83333333%;
}

.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}

.ant-col-xs-order-23 {
  order: 23;
}

.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.ant-col-xs-push-22 {
  left: 91.66666667%;
}

.ant-col-xs-pull-22 {
  right: 91.66666667%;
}

.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}

.ant-col-xs-order-22 {
  order: 22;
}

.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.ant-col-xs-push-21 {
  left: 87.5%;
}

.ant-col-xs-pull-21 {
  right: 87.5%;
}

.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}

.ant-col-xs-order-21 {
  order: 21;
}

.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.ant-col-xs-push-20 {
  left: 83.33333333%;
}

.ant-col-xs-pull-20 {
  right: 83.33333333%;
}

.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}

.ant-col-xs-order-20 {
  order: 20;
}

.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}

.ant-col-xs-push-19 {
  left: 79.16666667%;
}

.ant-col-xs-pull-19 {
  right: 79.16666667%;
}

.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}

.ant-col-xs-order-19 {
  order: 19;
}

.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}

.ant-col-xs-push-18 {
  left: 75%;
}

.ant-col-xs-pull-18 {
  right: 75%;
}

.ant-col-xs-offset-18 {
  margin-left: 75%;
}

.ant-col-xs-order-18 {
  order: 18;
}

.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}

.ant-col-xs-push-17 {
  left: 70.83333333%;
}

.ant-col-xs-pull-17 {
  right: 70.83333333%;
}

.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}

.ant-col-xs-order-17 {
  order: 17;
}

.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.ant-col-xs-push-16 {
  left: 66.66666667%;
}

.ant-col-xs-pull-16 {
  right: 66.66666667%;
}

.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}

.ant-col-xs-order-16 {
  order: 16;
}

.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.ant-col-xs-push-15 {
  left: 62.5%;
}

.ant-col-xs-pull-15 {
  right: 62.5%;
}

.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}

.ant-col-xs-order-15 {
  order: 15;
}

.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.ant-col-xs-push-14 {
  left: 58.33333333%;
}

.ant-col-xs-pull-14 {
  right: 58.33333333%;
}

.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}

.ant-col-xs-order-14 {
  order: 14;
}

.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}

.ant-col-xs-push-13 {
  left: 54.16666667%;
}

.ant-col-xs-pull-13 {
  right: 54.16666667%;
}

.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}

.ant-col-xs-order-13 {
  order: 13;
}

.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}

.ant-col-xs-push-12 {
  left: 50%;
}

.ant-col-xs-pull-12 {
  right: 50%;
}

.ant-col-xs-offset-12 {
  margin-left: 50%;
}

.ant-col-xs-order-12 {
  order: 12;
}

.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}

.ant-col-xs-push-11 {
  left: 45.83333333%;
}

.ant-col-xs-pull-11 {
  right: 45.83333333%;
}

.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}

.ant-col-xs-order-11 {
  order: 11;
}

.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.ant-col-xs-push-10 {
  left: 41.66666667%;
}

.ant-col-xs-pull-10 {
  right: 41.66666667%;
}

.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}

.ant-col-xs-order-10 {
  order: 10;
}

.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.ant-col-xs-push-9 {
  left: 37.5%;
}

.ant-col-xs-pull-9 {
  right: 37.5%;
}

.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}

.ant-col-xs-order-9 {
  order: 9;
}

.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.ant-col-xs-push-8 {
  left: 33.33333333%;
}

.ant-col-xs-pull-8 {
  right: 33.33333333%;
}

.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}

.ant-col-xs-order-8 {
  order: 8;
}

.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}

.ant-col-xs-push-7 {
  left: 29.16666667%;
}

.ant-col-xs-pull-7 {
  right: 29.16666667%;
}

.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}

.ant-col-xs-order-7 {
  order: 7;
}

.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}

.ant-col-xs-push-6 {
  left: 25%;
}

.ant-col-xs-pull-6 {
  right: 25%;
}

.ant-col-xs-offset-6 {
  margin-left: 25%;
}

.ant-col-xs-order-6 {
  order: 6;
}

.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}

.ant-col-xs-push-5 {
  left: 20.83333333%;
}

.ant-col-xs-pull-5 {
  right: 20.83333333%;
}

.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}

.ant-col-xs-order-5 {
  order: 5;
}

.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.ant-col-xs-push-4 {
  left: 16.66666667%;
}

.ant-col-xs-pull-4 {
  right: 16.66666667%;
}

.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}

.ant-col-xs-order-4 {
  order: 4;
}

.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.ant-col-xs-push-3 {
  left: 12.5%;
}

.ant-col-xs-pull-3 {
  right: 12.5%;
}

.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}

.ant-col-xs-order-3 {
  order: 3;
}

.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.ant-col-xs-push-2 {
  left: 8.33333333%;
}

.ant-col-xs-pull-2 {
  right: 8.33333333%;
}

.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}

.ant-col-xs-order-2 {
  order: 2;
}

.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.ant-col-xs-push-1 {
  left: 4.16666667%;
}

.ant-col-xs-pull-1 {
  right: 4.16666667%;
}

.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}

.ant-col-xs-order-1 {
  order: 1;
}

.ant-col-xs-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-xs-push-0 {
  left: auto;
}

.ant-col-xs-pull-0 {
  right: auto;
}

.ant-col-xs-offset-0 {
  margin-left: 0;
}

.ant-col-xs-order-0 {
  order: 0;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}

.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}

.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}

.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}

.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}

.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}

.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}

.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}

.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}

.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}

.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}

.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}

.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}

.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}

.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}

.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}

.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}

.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}

.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}

.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}

.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}

.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}

.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}

.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}

.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}

.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}

.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}

.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}

.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}

.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}

.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}

.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}

.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}

.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}

.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}

.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}

.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}

.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}

.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}

.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}

.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}

.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}

.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}

.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}

.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}

.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}

.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}

.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}

.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}

.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}

.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}

.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}

.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}

.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}

.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}

.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-sm-push-24 {
    left: 100%;
  }

  .ant-col-sm-pull-24 {
    right: 100%;
  }

  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }

  .ant-col-sm-order-24 {
    order: 24;
  }

  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }

  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-sm-order-23 {
    order: 23;
  }

  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }

  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-sm-order-22 {
    order: 22;
  }

  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-sm-push-21 {
    left: 87.5%;
  }

  .ant-col-sm-pull-21 {
    right: 87.5%;
  }

  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-sm-order-21 {
    order: 21;
  }

  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }

  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-sm-order-20 {
    order: 20;
  }

  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }

  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-sm-order-19 {
    order: 19;
  }

  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-sm-push-18 {
    left: 75%;
  }

  .ant-col-sm-pull-18 {
    right: 75%;
  }

  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }

  .ant-col-sm-order-18 {
    order: 18;
  }

  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }

  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-sm-order-17 {
    order: 17;
  }

  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }

  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-sm-order-16 {
    order: 16;
  }

  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-sm-push-15 {
    left: 62.5%;
  }

  .ant-col-sm-pull-15 {
    right: 62.5%;
  }

  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-sm-order-15 {
    order: 15;
  }

  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }

  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-sm-order-14 {
    order: 14;
  }

  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }

  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-sm-order-13 {
    order: 13;
  }

  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-sm-push-12 {
    left: 50%;
  }

  .ant-col-sm-pull-12 {
    right: 50%;
  }

  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }

  .ant-col-sm-order-12 {
    order: 12;
  }

  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }

  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-sm-order-11 {
    order: 11;
  }

  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }

  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-sm-order-10 {
    order: 10;
  }

  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-sm-push-9 {
    left: 37.5%;
  }

  .ant-col-sm-pull-9 {
    right: 37.5%;
  }

  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-sm-order-9 {
    order: 9;
  }

  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }

  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-sm-order-8 {
    order: 8;
  }

  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }

  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-sm-order-7 {
    order: 7;
  }

  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-sm-push-6 {
    left: 25%;
  }

  .ant-col-sm-pull-6 {
    right: 25%;
  }

  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }

  .ant-col-sm-order-6 {
    order: 6;
  }

  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }

  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-sm-order-5 {
    order: 5;
  }

  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }

  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-sm-order-4 {
    order: 4;
  }

  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-sm-push-3 {
    left: 12.5%;
  }

  .ant-col-sm-pull-3 {
    right: 12.5%;
  }

  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-sm-order-3 {
    order: 3;
  }

  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }

  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-sm-order-2 {
    order: 2;
  }

  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }

  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-sm-order-1 {
    order: 1;
  }

  .ant-col-sm-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-sm-push-0 {
    left: auto;
  }

  .ant-col-sm-pull-0 {
    right: auto;
  }

  .ant-col-sm-offset-0 {
    margin-left: 0;
  }

  .ant-col-sm-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-md-push-24 {
    left: 100%;
  }

  .ant-col-md-pull-24 {
    right: 100%;
  }

  .ant-col-md-offset-24 {
    margin-left: 100%;
  }

  .ant-col-md-order-24 {
    order: 24;
  }

  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-md-push-23 {
    left: 95.83333333%;
  }

  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-md-order-23 {
    order: 23;
  }

  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-md-push-22 {
    left: 91.66666667%;
  }

  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-md-order-22 {
    order: 22;
  }

  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-md-push-21 {
    left: 87.5%;
  }

  .ant-col-md-pull-21 {
    right: 87.5%;
  }

  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-md-order-21 {
    order: 21;
  }

  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-md-push-20 {
    left: 83.33333333%;
  }

  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-md-order-20 {
    order: 20;
  }

  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-md-push-19 {
    left: 79.16666667%;
  }

  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-md-order-19 {
    order: 19;
  }

  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-md-push-18 {
    left: 75%;
  }

  .ant-col-md-pull-18 {
    right: 75%;
  }

  .ant-col-md-offset-18 {
    margin-left: 75%;
  }

  .ant-col-md-order-18 {
    order: 18;
  }

  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-md-push-17 {
    left: 70.83333333%;
  }

  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-md-order-17 {
    order: 17;
  }

  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-md-push-16 {
    left: 66.66666667%;
  }

  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-md-order-16 {
    order: 16;
  }

  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-md-push-15 {
    left: 62.5%;
  }

  .ant-col-md-pull-15 {
    right: 62.5%;
  }

  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-md-order-15 {
    order: 15;
  }

  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-md-push-14 {
    left: 58.33333333%;
  }

  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-md-order-14 {
    order: 14;
  }

  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-md-push-13 {
    left: 54.16666667%;
  }

  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-md-order-13 {
    order: 13;
  }

  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-md-push-12 {
    left: 50%;
  }

  .ant-col-md-pull-12 {
    right: 50%;
  }

  .ant-col-md-offset-12 {
    margin-left: 50%;
  }

  .ant-col-md-order-12 {
    order: 12;
  }

  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-md-push-11 {
    left: 45.83333333%;
  }

  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-md-order-11 {
    order: 11;
  }

  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-md-push-10 {
    left: 41.66666667%;
  }

  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-md-order-10 {
    order: 10;
  }

  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-md-push-9 {
    left: 37.5%;
  }

  .ant-col-md-pull-9 {
    right: 37.5%;
  }

  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-md-order-9 {
    order: 9;
  }

  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-md-push-8 {
    left: 33.33333333%;
  }

  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-md-order-8 {
    order: 8;
  }

  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-md-push-7 {
    left: 29.16666667%;
  }

  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-md-order-7 {
    order: 7;
  }

  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-md-push-6 {
    left: 25%;
  }

  .ant-col-md-pull-6 {
    right: 25%;
  }

  .ant-col-md-offset-6 {
    margin-left: 25%;
  }

  .ant-col-md-order-6 {
    order: 6;
  }

  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-md-push-5 {
    left: 20.83333333%;
  }

  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-md-order-5 {
    order: 5;
  }

  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-md-push-4 {
    left: 16.66666667%;
  }

  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-md-order-4 {
    order: 4;
  }

  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-md-push-3 {
    left: 12.5%;
  }

  .ant-col-md-pull-3 {
    right: 12.5%;
  }

  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-md-order-3 {
    order: 3;
  }

  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-md-push-2 {
    left: 8.33333333%;
  }

  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-md-order-2 {
    order: 2;
  }

  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-md-push-1 {
    left: 4.16666667%;
  }

  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-md-order-1 {
    order: 1;
  }

  .ant-col-md-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-md-push-0 {
    left: auto;
  }

  .ant-col-md-pull-0 {
    right: auto;
  }

  .ant-col-md-offset-0 {
    margin-left: 0;
  }

  .ant-col-md-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-lg-push-24 {
    left: 100%;
  }

  .ant-col-lg-pull-24 {
    right: 100%;
  }

  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }

  .ant-col-lg-order-24 {
    order: 24;
  }

  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }

  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-lg-order-23 {
    order: 23;
  }

  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }

  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-lg-order-22 {
    order: 22;
  }

  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-lg-push-21 {
    left: 87.5%;
  }

  .ant-col-lg-pull-21 {
    right: 87.5%;
  }

  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-lg-order-21 {
    order: 21;
  }

  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }

  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-lg-order-20 {
    order: 20;
  }

  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }

  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-lg-order-19 {
    order: 19;
  }

  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-lg-push-18 {
    left: 75%;
  }

  .ant-col-lg-pull-18 {
    right: 75%;
  }

  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }

  .ant-col-lg-order-18 {
    order: 18;
  }

  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }

  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-lg-order-17 {
    order: 17;
  }

  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }

  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-lg-order-16 {
    order: 16;
  }

  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-lg-push-15 {
    left: 62.5%;
  }

  .ant-col-lg-pull-15 {
    right: 62.5%;
  }

  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-lg-order-15 {
    order: 15;
  }

  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }

  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-lg-order-14 {
    order: 14;
  }

  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }

  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-lg-order-13 {
    order: 13;
  }

  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-lg-push-12 {
    left: 50%;
  }

  .ant-col-lg-pull-12 {
    right: 50%;
  }

  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }

  .ant-col-lg-order-12 {
    order: 12;
  }

  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }

  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-lg-order-11 {
    order: 11;
  }

  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }

  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-lg-order-10 {
    order: 10;
  }

  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-lg-push-9 {
    left: 37.5%;
  }

  .ant-col-lg-pull-9 {
    right: 37.5%;
  }

  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-lg-order-9 {
    order: 9;
  }

  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }

  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-lg-order-8 {
    order: 8;
  }

  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }

  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-lg-order-7 {
    order: 7;
  }

  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-lg-push-6 {
    left: 25%;
  }

  .ant-col-lg-pull-6 {
    right: 25%;
  }

  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }

  .ant-col-lg-order-6 {
    order: 6;
  }

  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }

  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-lg-order-5 {
    order: 5;
  }

  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }

  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-lg-order-4 {
    order: 4;
  }

  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-lg-push-3 {
    left: 12.5%;
  }

  .ant-col-lg-pull-3 {
    right: 12.5%;
  }

  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-lg-order-3 {
    order: 3;
  }

  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }

  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-lg-order-2 {
    order: 2;
  }

  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }

  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-lg-order-1 {
    order: 1;
  }

  .ant-col-lg-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-lg-push-0 {
    left: auto;
  }

  .ant-col-lg-pull-0 {
    right: auto;
  }

  .ant-col-lg-offset-0 {
    margin-left: 0;
  }

  .ant-col-lg-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xl-push-24 {
    left: 100%;
  }

  .ant-col-xl-pull-24 {
    right: 100%;
  }

  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xl-order-24 {
    order: 24;
  }

  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }

  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-xl-order-23 {
    order: 23;
  }

  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }

  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-xl-order-22 {
    order: 22;
  }

  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-xl-push-21 {
    left: 87.5%;
  }

  .ant-col-xl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xl-order-21 {
    order: 21;
  }

  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }

  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-xl-order-20 {
    order: 20;
  }

  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }

  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-xl-order-19 {
    order: 19;
  }

  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-xl-push-18 {
    left: 75%;
  }

  .ant-col-xl-pull-18 {
    right: 75%;
  }

  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xl-order-18 {
    order: 18;
  }

  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }

  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-xl-order-17 {
    order: 17;
  }

  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }

  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-xl-order-16 {
    order: 16;
  }

  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-xl-push-15 {
    left: 62.5%;
  }

  .ant-col-xl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xl-order-15 {
    order: 15;
  }

  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }

  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-xl-order-14 {
    order: 14;
  }

  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }

  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-xl-order-13 {
    order: 13;
  }

  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-xl-push-12 {
    left: 50%;
  }

  .ant-col-xl-pull-12 {
    right: 50%;
  }

  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xl-order-12 {
    order: 12;
  }

  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }

  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-xl-order-11 {
    order: 11;
  }

  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }

  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-xl-order-10 {
    order: 10;
  }

  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-xl-push-9 {
    left: 37.5%;
  }

  .ant-col-xl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xl-order-9 {
    order: 9;
  }

  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }

  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-xl-order-8 {
    order: 8;
  }

  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }

  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-xl-order-7 {
    order: 7;
  }

  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-xl-push-6 {
    left: 25%;
  }

  .ant-col-xl-pull-6 {
    right: 25%;
  }

  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xl-order-6 {
    order: 6;
  }

  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }

  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-xl-order-5 {
    order: 5;
  }

  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }

  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-xl-order-4 {
    order: 4;
  }

  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-xl-push-3 {
    left: 12.5%;
  }

  .ant-col-xl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xl-order-3 {
    order: 3;
  }

  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }

  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-xl-order-2 {
    order: 2;
  }

  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }

  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-xl-order-1 {
    order: 1;
  }

  .ant-col-xl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xl-push-0 {
    left: auto;
  }

  .ant-col-xl-pull-0 {
    right: auto;
  }

  .ant-col-xl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xxl-push-24 {
    left: 100%;
  }

  .ant-col-xxl-pull-24 {
    right: 100%;
  }

  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xxl-order-24 {
    order: 24;
  }

  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }

  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-xxl-order-23 {
    order: 23;
  }

  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }

  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-xxl-order-22 {
    order: 22;
  }

  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-xxl-push-21 {
    left: 87.5%;
  }

  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xxl-order-21 {
    order: 21;
  }

  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }

  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-xxl-order-20 {
    order: 20;
  }

  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }

  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-xxl-order-19 {
    order: 19;
  }

  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-xxl-push-18 {
    left: 75%;
  }

  .ant-col-xxl-pull-18 {
    right: 75%;
  }

  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xxl-order-18 {
    order: 18;
  }

  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }

  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-xxl-order-17 {
    order: 17;
  }

  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }

  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-xxl-order-16 {
    order: 16;
  }

  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-xxl-push-15 {
    left: 62.5%;
  }

  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xxl-order-15 {
    order: 15;
  }

  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }

  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-xxl-order-14 {
    order: 14;
  }

  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }

  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-xxl-order-13 {
    order: 13;
  }

  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-xxl-push-12 {
    left: 50%;
  }

  .ant-col-xxl-pull-12 {
    right: 50%;
  }

  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xxl-order-12 {
    order: 12;
  }

  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }

  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-xxl-order-11 {
    order: 11;
  }

  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }

  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-xxl-order-10 {
    order: 10;
  }

  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-xxl-push-9 {
    left: 37.5%;
  }

  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xxl-order-9 {
    order: 9;
  }

  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }

  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-xxl-order-8 {
    order: 8;
  }

  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }

  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-xxl-order-7 {
    order: 7;
  }

  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-xxl-push-6 {
    left: 25%;
  }

  .ant-col-xxl-pull-6 {
    right: 25%;
  }

  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xxl-order-6 {
    order: 6;
  }

  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }

  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-xxl-order-5 {
    order: 5;
  }

  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }

  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-xxl-order-4 {
    order: 4;
  }

  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-xxl-push-3 {
    left: 12.5%;
  }

  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xxl-order-3 {
    order: 3;
  }

  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }

  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-xxl-order-2 {
    order: 2;
  }

  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }

  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-xxl-order-1 {
    order: 1;
  }

  .ant-col-xxl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xxl-push-0 {
    left: auto;
  }

  .ant-col-xxl-pull-0 {
    right: auto;
  }

  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xxl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

.ant-row-rtl {
  direction: rtl;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}

.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}

.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}

.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}

.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}

.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.ant-divider-rtl {
  direction: rtl;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
}

.ant-btn > .anticon {
  line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none;
}

.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}

.ant-btn[disabled] {
  cursor: not-allowed;
}

.ant-btn[disabled] > * {
  pointer-events: none;
}

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 4px;
}

.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 4px;
}

.ant-btn > a:only-child {
  color: currentColor;
}

.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:hover,
.ant-btn:focus {
  color: #ff6378;
  background: #fff;
  border-color: #ff6378;
}

.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}

.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:active {
  color: #d12648;
  background: #fff;
  border-color: #d12648;
}

.ant-btn:active > a:only-child {
  color: currentColor;
}

.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}

.ant-btn > span {
  display: inline-block;
}

.ant-btn-primary {
  color: #fff;
  background: #f73859;
  border-color: #f73859;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #ff6378;
  border-color: #ff6378;
}

.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary:active {
  color: #fff;
  background: #d12648;
  border-color: #d12648;
}

.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #ff6378;
  border-left-color: #ff6378;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #ff6378;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #ff6378;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}

.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: #d9d9d9;
}

.ant-btn-ghost > a:only-child {
  color: currentColor;
}

.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #ff6378;
  background: transparent;
  border-color: #ff6378;
}

.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost:active {
  color: #d12648;
  background: transparent;
  border-color: #d12648;
}

.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}

.ant-btn-dashed > a:only-child {
  color: currentColor;
}

.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #ff6378;
  background: #fff;
  border-color: #ff6378;
}

.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed:active {
  color: #d12648;
  background: #fff;
  border-color: #d12648;
}

.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link {
  color: #f73859;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #ff6378;
  background: transparent;
  border-color: #ff6378;
}

.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:active {
  color: #d12648;
  background: transparent;
  border-color: #d12648;
}

.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:hover {
  background: transparent;
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #ff6378;
  background: transparent;
  border-color: #ff6378;
}

.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:active {
  color: #d12648;
  background: transparent;
  border-color: #d12648;
}

.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}

.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}

.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875;
}

.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e;
}

.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff6378;
  background: transparent;
  border-color: #ff6378;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d12648;
  background: transparent;
  border-color: #d12648;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff6378;
  background: transparent;
  border-color: #ff6378;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d12648;
  background: transparent;
  border-color: #d12648;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 4px;
  vertical-align: -1px;
}

.ant-btn-icon-only > * {
  font-size: 16px;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 4px;
}

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 4px;
}

.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}

.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}

.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}

.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}

.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}

.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}

.ant-btn.ant-btn-loading {
  position: relative;
}

.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}

.ant-btn.ant-btn-loading::before {
  display: block;
}

.ant-btn > .ant-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  -webkit-animation: none;
          animation: none;
}

.ant-btn > .ant-btn-loading-icon .anticon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
}

.ant-btn-group {
  position: relative;
  display: inline-flex;
}

.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}

.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}

.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}

.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0;
}

.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 0;
}

.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}

.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}

.ant-btn-group .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}

.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}

.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}

.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}

.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ant-btn-group > .ant-btn-group {
  float: left;
}

.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}

.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #f73859;
  background: transparent;
  border-color: #f73859;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #ff6378;
  background: transparent;
  border-color: #ff6378;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #d12648;
  background: transparent;
  border-color: #d12648;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}

.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}

.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}

a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}

a.ant-btn-lg {
  line-height: 38px;
}

a.ant-btn-sm {
  line-height: 22px;
}

.ant-btn-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #ff6378;
  border-left-color: #d9d9d9;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #ff6378;
}

.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-pink .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}

.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}

.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}

.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}

.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}

.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}

.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1;
}

.ant-tooltip-rtl {
  direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f73859;
}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f73859;
  border-radius: 4px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}

.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}

.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f73859;
  border-color: #f73859;
}

.ant-checkbox-disabled {
  cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}

.ant-checkbox-group-item {
  margin-right: 8px;
}

.ant-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #f73859;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-rtl {
  direction: rtl;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: auto;
}

.ant-form-item .ant-upload {
  background: transparent;
}

.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}

.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}

.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}

.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0;
}

.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block;
}

.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}

.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}

.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
}

.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100%;
}

.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
  width: auto;
}

.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}

.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}

.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}

.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}

.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}

.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
}

.ant-form-vertical .ant-form-item {
  flex-direction: column;
}

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}

.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}

.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}

.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }

  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}

.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item-explain.ant-form-item-explain-warning {
  color: #faad14;
}

.ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}

.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}

.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}

.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: 32px;
}

.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}

.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}

.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
}

.ant-form-item-has-feedback .ant-picker {
  padding-right: 29.2px;
}

.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px;
}

.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}

.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}

.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #faad14;
}

.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}

.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-prefix {
  color: #faad14;
}

.ant-form-item-has-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-form-item-has-warning .has-feedback {
  color: #faad14;
}

.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: #fff;
  border-color: #faad14;
}

.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}

.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-form-item-has-error .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}

.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-form-item-has-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .has-feedback {
  color: #ff4d4f;
}

.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff4d4f;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}

.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: #fff;
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #ff6378;
  border-right-width: 1px !important;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #ff6378;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(247, 56, 89, 0.2);
}

.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: #ff4d4f !important;
}

.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff4d4f;
}

.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #f73859;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}

.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ant-form label {
  font-size: 14px;
}

.ant-form input[type='search'] {
  box-sizing: border-box;
}

.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}

.ant-form input[type='file'] {
  display: block;
}

.ant-form input[type='range'] {
  display: block;
  width: 100%;
}

.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}

.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}

.ant-form-small .ant-form-item-label > label {
  height: 24px;
}

.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}

.ant-form-large .ant-form-item-label > label {
  height: 40px;
}

.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}

.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}

.ant-form-item-label-left {
  text-align: left;
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}

[dir="ltr"] .ant-form-item-label > label .ant-form-item-tooltip {
  margin-left: 4px;
}

[dir="rtl"] .ant-form-item-label > label .ant-form-item-tooltip {
  margin-right: 4px;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
}

.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}

.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}

.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-form-item .ant-input-textarea-show-count::after {
  margin-bottom: -22px;
}

.ant-show-help-enter,
.ant-show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.ant-show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.ant-show-help-enter.ant-show-help-enter-active,
.ant-show-help-appear.ant-show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.ant-show-help-leave.ant-show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.ant-show-help-enter,
.ant-show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

@-webkit-keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}

@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}

@-webkit-keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.ant-form-rtl {
  direction: rtl;
}

.ant-form-rtl .ant-form-item-label {
  text-align: left;
}

.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}

.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}

.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}

.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}

.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  display: inline-flex;
}

.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
}

.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
  border-color: #ff6378;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #ff6378;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(247, 56, 89, 0.2);
}

.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}

.ant-input-affix-wrapper-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ff6378;
  border-right-width: 1px !important;
  z-index: 1;
}

.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none;
}

.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}

.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}

.ant-input-prefix {
  margin-right: 4px;
}

.ant-input-suffix {
  margin-left: 4px;
}

.ant-input-clear-icon {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input-clear-icon-hidden {
  visibility: hidden;
}

.ant-input-clear-icon:last-child {
  margin-right: 0;
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}

.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.ant-input::-moz-placeholder {
  opacity: 1;
}

.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-input::placeholder {
  color: #bfbfbf;
}

.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:hover {
  border-color: #ff6378;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #ff6378;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(247, 56, 89, 0.2);
}

.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-sm {
  padding: 0px 7px;
}

.ant-input-rtl {
  direction: rtl;
}

.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}

.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}

.ant-input-group-wrap > * {
  display: block !important;
}

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}

.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}

.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}

.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #f73859;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon:first-child {
  border-right: 0;
}

.ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ant-input-group.ant-input-group-compact {
  display: block;
}

.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}

.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 4px 0 0 4px;
}

.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 4px 4px 0;
}

.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
}

.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 4px 0 0 4px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 4px 0 0 4px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 4px 4px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 4px 4px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 4px 0 0 4px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 4px 4px 0;
}

[dir="ltr"] .ant-input-group-wrapper {
  text-align: left;
}

[dir="rtl"] .ant-input-group-wrapper {
  text-align: right;
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}

.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input[type='color'] {
  height: 32px;
}

.ant-input[type='color'].ant-input-lg {
  height: 40px;
}

.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #ff6378;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #ff6378;
}

.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 4px 4px 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ant-input-search-button {
  height: 32px;
}

.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-group-wrapper-rtl {
  direction: rtl;
}

.ant-input-group-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}

.ant-input-textarea-rtl {
  direction: rtl;
}

.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}

.ant-input-search-rtl {
  direction: rtl;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #ff6378;
  border-left-color: #d9d9d9;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #ff6378;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 4px 0 0 4px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }

  .ant-input-lg {
    height: 40px;
  }

  .ant-input-sm {
    height: 24px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.ant-avatar-image {
  background: transparent;
}

.ant-avatar .ant-image-img {
  display: block;
}

.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}

.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}

.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}

.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}

.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}

.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-square {
  border-radius: 4px;
}

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.ant-avatar-group {
  display: inline-flex;
}

.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}

.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}

.ant-popover-hidden {
  display: none;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}

.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}

.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }

  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}

.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}

.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px;
}

.ant-popover-message-title {
  padding-left: 22px;
}

.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}

.ant-popover-buttons button {
  margin-left: 8px;
}

.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}

.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}

.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}

.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}

.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}

.ant-dropdown-wrap {
  position: relative;
}

.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}

.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}

.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}

.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}

.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}

.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}

.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}

.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}

.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #f73859;
  background-color: #fff0f0;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled > .anticon + span > a,
.ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
.ant-dropdown-menu-item-disabled > a,
.ant-dropdown-menu-submenu-title-disabled > a {
  position: relative;
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.ant-dropdown-menu-item-disabled > .anticon + span > a::after,
.ant-dropdown-menu-submenu-title-disabled > .anticon + span > a::after,
.ant-dropdown-menu-item-disabled > a::after,
.ant-dropdown-menu-submenu-title-disabled > a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  content: '';
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}

.ant-dropdown-menu-submenu-vertical {
  position: relative;
}

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #f73859;
}

.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}

.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}

.ant-dropdown-button {
  white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #f73859;
}

.ant-dropdown-rtl {
  direction: rtl;
}

.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}

.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}

.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}

.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-menu::before {
  display: table;
  content: '';
}

.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}

.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-menu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}

.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-selected {
  color: #f73859;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fff0f0;
}

.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item a:hover {
  color: #f73859;
}

.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}

.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item > .ant-badge a:hover {
  color: #f73859;
}

.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #f73859;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}

.ant-menu-item-selected {
  color: #f73859;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #f73859;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff0f0;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}

.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}

.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}

.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 4px;
  box-shadow: none;
  transform-origin: 0 0;
}

.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}

.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}

.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 4px;
}

.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}

.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}

.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #f73859;
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #f73859;
}

.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #f73859;
  border-bottom: 2px solid #f73859;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #f73859;
}

.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #f73859;
}

.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #f73859;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}

.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline {
  width: 100%;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline-collapsed {
  width: 80px;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}

.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}

.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}

.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}

.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}

.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}

.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #f73859;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}

.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f73859;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #f73859;
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}

.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}

.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}

.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}

.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}

.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}

.ant-menu-sub.ant-menu-inline {
  border: 0;
}

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
}

.ant-modal-footer .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #f73859;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* 系统主题颜色 */

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}

.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}

.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}

.ant-tabs-rtl {
  direction: rtl;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 0;
  margin-left: 2px;
}

.ant-tabs-dropdown-rtl {
  direction: rtl;
}

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}

.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}

.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}

.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}

.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}

.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}

.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}

.ant-tabs-dropdown-hidden {
  display: none;
}

.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}

.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #f73859;
  background: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px 4px 0 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 4px 4px;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px 0 0 4px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 4px 4px 0;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}

.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  overflow: hidden;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #ff6378;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #d12648;
}

.ant-tabs-extra-content {
  flex: none;
}

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #f73859;
  pointer-events: none;
}

.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #d12648;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}

.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-tabs-tab:hover {
  color: #ff6378;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f73859;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-tab .anticon {
  margin-right: 12px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-content {
  display: flex;
  width: 100%;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-content-animated {
  transition: margin 0.3s;
}

.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}

/* purgecss end ignore */

